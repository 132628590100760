import React from 'react';
import { Link } from 'react-router-dom';
import './NewTopPage.css';

function NewTopPage() {
  // テキストに含める改行を含む変数を定義
  const textWithBreak = '料理を愛するあなたの為に<br>生まれたアプリです。';

  return (
    <div className="newtop-page">
      <div className="nt-ht">天使のおまもり</div>
      <div className="nt-ht2" dangerouslySetInnerHTML={{ __html: textWithBreak }}></div>
      <div className="auth-links">
        <Link to="/signin" className="auth-link">登録済みの方はこちら</Link>
        <Link to="/signup" className="auth-link">新規登録はこちら</Link>
      </div>
{/* プライバシーポリシーのリンクを追加 */}
<div className="privacy-policy-link">
        <Link to="/privacy-policy">プライバシーポリシー</Link>
      </div>

    </div>
  );
}

export default NewTopPage;
