import React from 'react';
import './RecipeForm6.css';

const RecipeForm6 = ({
  selectedCategories,
  handleCategoryChange,
  categoryOptions
}) => {
  return (
    <>
      {['category1', 'category2', 'category3', 'category4'].map((category, index) => (
        <div className="row mb-3 remove-margin-bottom align-items-center" key={index}>
          　　<label className="col-2 header-text-shifted">{`カテゴリー${index + 1}`}</label>
          <div className="col-4">
            <select 
              value={selectedCategories[category]} 
              onChange={(e) => handleCategoryChange(e, category)} 
              className="ctform"
            >
              <option value="">選択してください</option>
              {categoryOptions.map((categoryOption) => (
                <option value={categoryOption} key={categoryOption}>{categoryOption}</option>
              ))}
            </select>
          </div>
        </div>
      ))}
    </>
  );
};

export default RecipeForm6;
