import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // スタイルを適用するために追加

function Home() {
  // ボタンの情報を配列で定義
  const buttons = [
    { name: 'myキッチン', link: '/top', icon: 'icon-path-kitchen' },
    { name: 'みんなのごはん堂', link: '/community', icon: 'icon-path-community' },
    { name: 'レシピを送る', link: '/submit-recipe', icon: 'icon-path-submit' },
    { name: 'レシピを受け取る', link: '/receive-recipes', icon: 'icon-path-receive-recipes' },
    { name: '買い物リスト', link: '/shopping-list', icon: 'icon-path-shopping' },
    { name: 'myプロフィール', link: '/profile', icon: 'icon-path-profile' },
    { name: '問い合わせ', link: '/contact', icon: 'icon-path-contact' },
    { name: '円と元気を送る', link: '/donate', icon: 'icon-path-donate' }
  ];
  
  return (
    <div className="home-container">
    <div>　
      
    <div className="page-title pt-outlined ">天使のおまもり</div>
    
    <div className="homebuttons-container">
      {buttons.map((button, index) => (
        <Link to={button.link} className="home-button" key={index}>
          <div className={`button-icon ${button.icon}`}></div>
          <div className="button-text">{button.name}</div>
        </Link>
      ))}
    </div>
    </div>
    </div>
  );
}

export default Home;

