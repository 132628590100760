/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "Auth": {
        "region": "ap-northeast-1",
        "userPoolId": "ap-northeast-1_xk27gJB4Q",
        "userPoolWebClientId": "2f7ohsgppe1b5h7vnku19aco8u"
    }
};


export default awsmobile;
