import React from 'react';
import { Link } from 'react-router-dom';
import './ShoppingList.css';


function ShoppingList({ shoppingList, setShoppingList }) {

    const handleAddItem = () => {
        setShoppingList(prevItems => [...prevItems, { name: "", quantity: "", note: "" }]);
      };
      

  const handleChange = (index, field, value) => {
    const newItems = [...shoppingList];
    newItems[index][field] = value;
    setShoppingList(newItems);
  };

  const handleDelete = (index) => {
    const newItems = [...shoppingList];
    newItems.splice(index, 1);
    setShoppingList(newItems);
  };

  return (
    <div className="shoppingList shopcontainer">
      <div className="row mb-3">
        <div className="col-12 d-flex justify-content-center">
          <h2>買い物リスト</h2>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4">
          <Link to="/home" className="btn shopgreenbtn1 small-text-btn">ホームへ戻る</Link>
        </div>
        <div className="col-4 text-right">
          <button onClick={handleAddItem} className="btn shopgreenbtn2 small-text-btn">アイテム追加</button>
        </div>
      </div>
      <hr className="shophr1"/>
      {shoppingList.map((item, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-4">
            <input 
              type="text" 
              className="form-control shop-form1"
              placeholder="アイテムを入力"
              value={item.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
            />
          </div>
          <div className="col-2">
          <input 
  type="number"
  className="form-control shop-form2"
  placeholder="数量"
  min="0"  
  value={item.quantity}
  onChange={(e) => handleChange(index, 'quantity', e.target.value)}
/>

          </div>
          <div className="col-5">
            <input 
              type="text"
              className="form-control shop-form3"
              placeholder="備考"
              value={item.note}
              onChange={(e) => handleChange(index, 'note', e.target.value)}
            />
          </div>
          <div className="col-1 text-right">
            <button onClick={() => handleDelete(index)} className="btn btn-danger shop-redbtn">削除</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ShoppingList;

