import React, { useState, useEffect } from 'react';

import MyModal from './MyModal';
import './RecipeForm2.css';

const RecipeForm2 = ({ 
    isShoppingMode,
    formSets, 
    recipeStatus, 
    handleFormSetChange, 
    handleIngredientAmountChange, 
    unitOptions, 
    resultOptions, 
    handleRemoveIngredient, 
    handleAddIngredient,
    handleAddItem,
    setFormSets,
    isModified, 
    setIsModified
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFormSet, setSelectedFormSet] = useState(null);
const [selectedIndex, setSelectedIndex] = useState(null);
const [modalType, setModalType] = useState(null);


const handleSubmit = (formData) => {
    if (selectedIndex !== null) {
      const newFormSets = [...formSets];
      newFormSets[selectedIndex] = formData;
      
      // ここでnewFormSetsを親コンポーネントに渡す処理を行う
      setFormSets(newFormSets);  // この行を追加
      setIsModified(true);
    }
  };
  
  

    
  
    const handleFormClick = (value) => {
        if (isShoppingMode) {
          handleAddItem({ name: value, quantity: 1 });
        } else {
          setIsModalOpen(true);  // モーダルを開く
        }
      };
    
     

      const handleOpenModal = (formSet, index, modalType) => {
        setSelectedFormSet(() => formSet);
        setSelectedIndex(() => index); 
        setModalType(modalType);  
        setIsModalOpen(true);    
};
    
useEffect(() => {
  }, [isModalOpen]);
  
  useEffect(() => {
  }, [selectedFormSet]);
  
  useEffect(() => {
  }, [selectedIndex]);
  
        

   
return (
    <>
      {formSets && formSets.map((formSet, index) => (
<div className={`formset-container ${index === 0 ? 'first-formset' : ''}`} key={index}>
    {index === 0 && (
<div className="row mb-2">
<div className={`col-${recipeStatus === 'completed' ? '7' : '4'} p-0 m-0`}>
    <label className={`header-text ${recipeStatus === 'completed' ? 'header-text-shifted' : ''}`}>使用食材</label>

</div>
<div className={`col-${recipeStatus === 'completed' ? '4' : '2'} p-0 m-0`}>
    <label className={`header-text ${recipeStatus === 'completed' ? 'header-text-shifted' : ''}`}>使用量</label>
</div>
    {recipeStatus === 'adjusting' && (
<div className="col-2 p-0 m-0">
    <label className="header-text">単位</label>
</div>
    )}
    {recipeStatus === 'adjusting' && (
<div className="col-2 p-0 m-0">
    <label className="header-text">結果</label>
</div>
    )}
    
{recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}
{recipeStatus === 'adjusting' && (
    <div className="col-4 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}
{recipeStatus === 'adjusting' && (
<div className="col-2 p-0 m-0">
    <label className="header-text">/次回予定量</label>
</div>
    )}

    {recipeStatus === 'adjusting' && (
<div className="col-5 p-0 m-0">
    <label className="header-text">調整履歴</label>
</div>
    )}
    {recipeStatus === 'adjusting' && (
<div className="col-1 p-0 m-0">
    <label className="header-text">操作</label>
</div>
    )}
</div>
    )}

<div className="row mb-3">{/* 使用食材フォーム */}
<div className={`col-${recipeStatus === 'completed' ? '7' : '4'} p-0 m-0`}>
    {recipeStatus === 'completed' ? (
        <div className="completed-text">{formSet.ingredient}</div>
    ) : (
        isShoppingMode ? (
            <button
              className="form-control btn btn-primary2 adjustable2-font-size"
              onClick={() => handleFormClick(formSet.ingredient)}
            >
              {formSet.ingredient ? formSet.ingredient : "使用食材"}
            </button>
        ) : (
            <input 
                type="text"
                className="form-control"
                id="ingredientInput"
                name="ingredient"
                placeholder="使用食材" 
                value={formSet.ingredient}
                onClick={() => handleOpenModal(formSet, index, 'ingredient')}
                onChange={(e) => handleFormSetChange(e, index, 'ingredient')}
                autoComplete="off"
            />
        )
    )}
</div>



<div className={`col-2 p-0 m-0 ${recipeStatus === 'completed' ? 'text-right-when-completed' : ''}`}>
    {recipeStatus === 'completed' ? (
    <div>{formSet.amount}</div>
    ) : (
    <input 
        type="number" 
        className="form-control" 
        id="amountInput"
        name="amount"
        placeholder="使用量" 
        step="0.1"
        min="0"  // 最小値を0に設定して、マイナス値を防ぐ
        value={formSet.amount}
        onChange={(e) => handleIngredientAmountChange(e, index, formSet)}
    />
    )}
</div>

<div className="col-2 p-0 m-0">{/* 単位フォーム */}
    {recipeStatus === 'completed' ? (
    <div className="completed-text2">{formSet.unit}</div>
    ) : (
    <select  
        className="form-control" 
        id={`unitSelect-${index}`}
        name="unit"
        value={formSet.unit}
        onChange={(e) => {
        const newValue = e.target.value;
        const previousValue = formSet.unit;

        if (newValue !== previousValue) {
        handleFormSetChange(e, index, 'unit');}}}
    >
    {unitOptions.map((unit, unitIndex) => (
    <option key={unitIndex} value={unit}>{unit ? unit : '単位'}</option>
    ))}
    </select>
    )}
</div>

{recipeStatus === 'adjusting' && (
<div className="col-2 p-0 m-0">{/* 結果フォーム */}   
    <select 
         id={`resultInput-${index}`} 
        className="form-control smaller-text" 
        value={formSet.result}
        onChange={(e) => handleFormSetChange(e, index, 'result')}
    >
        {resultOptions.map((result, resultIndex) => (
    <option key={resultIndex} value={result}>{result ? result : '結果'}</option>
  ))}
    </select>
</div>
    )}


{recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}
{recipeStatus === 'adjusting' && (
    <div className="col-4 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}





    {recipeStatus === 'adjusting' && (
<div className="col-2 p-0 m-0"> {/* 次回予定量フォーム */}
    <input 
        type="number" 
        className="form-control" 
        id="nextUsageAmountInput"
        name="nextUsageAmount"
        placeholder="次回予定" 
        step="0.1"  // 0.1単位での変化を許可
        min="0"
        value={formSet.nextUsageAmount}
        onChange={(e) => {
        if (e.target.value < 0) {  // 値が0未満の場合、0に設定
        e.target.value = 0;
    }
        handleFormSetChange(e, index, 'nextUsageAmount');
    }}
    />
</div>
    )}

    {recipeStatus === 'adjusting' && (
<div className="col-5 p-0 m-0">{/* 調整履歴フォーム */}
    <input 
        type="text" 
        className="form-control" 
        id="adjustmentHistoryInput"
        name="adjustmentHistory"
        placeholder="調整履歴" 
        value={formSet.adjustmentHistory}
        onChange={(e) => handleFormSetChange(e, index, 'adjustmentHistory')}
    />
</div>
    )}

    {recipeStatus === 'adjusting' && (
<div className="col-1 d-flex align-items-center">{/* 削除ボタン */}
    <button className="btn btn-danger small-text-btn no-wrap-btn" onClick={(e) => {
        if (window.confirm("削除しますか？")) {
        handleRemoveIngredient(index);
    }
    }}>削除</button>
</div>
    )}
</div>
{recipeStatus === 'adjusting' && <hr />}
</div>  
))}
    {recipeStatus === 'adjusting' && (
<div className="row">
<div className="col p-0 m-0">{/* 食材追加ボタン */}
    <button className="btn btn-success mt-1 mb-3 small-text-btn" onClick={handleAddIngredient}>食材追加+</button>
</div>
</div>
    )}
 
 <div>
      
        <MyModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialData={selectedFormSet}
          onSubmit={handleSubmit}
          unitOptions={unitOptions}
          resultOptions={resultOptions}
          modalType={modalType}
        />
    </div>
    
   


   
    </>
    )};
    ;

export default RecipeForm2;

