// ReceiveRecipePage.js
import React from 'react';

function ReceiveRecipePage() {
  return (
    <div>
      <h1>レシピを受け取る</h1>
      <p>ここで他の人からのレシピを見ることができます。</p>
    </div>
  );
}

export default ReceiveRecipePage;
