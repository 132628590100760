// ContactPage.js
import React from 'react';

function ContactPage() {
  return (
    <div>
      <h1>問い合わせ</h1>
      <p>ご質問やフィードバックがあれば、こちらからお問い合わせください。</p>
      {/* お問い合わせフォームなど、必要なUIコンポーネントをここに追加します */}
    </div>
  );
}

export default ContactPage;
