import React, { useState } from 'react';
import MyModal from './MyModal';

const RecipeForm3 = ({ 
    seasoningSets, 
    recipeStatus, 
    handleSeasoningSetChange, 
    handleSeasoningAmountChange, 
    unitOptions, 
    resultOptions, 
    handleRemoveSeasoningSet, 
    handleAddSeasoningSet,
    handleAddItem,
    isShoppingMode,
    setSeasoningSets,
    isModified, 
    setIsModified 
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSeasoningSet, setSelectedSeasoningSet] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [modalType, setModalType] = useState(null);
    
    const handleButtonSeasoningClick = (seasoning) => {
        if (isShoppingMode) {
          handleAddItem({ name: seasoning, quantity: 1 });  // オブジェクトとして渡す
        }
      };
      
      const handleOpenModal = (seasoningSet, index, type) => {
        setSelectedSeasoningSet(seasoningSet);
        setSelectedIndex(index);
        setModalType(modalType);
        setIsModalOpen(true);
      };
    
      const handleSubmit = (formData) => {
        if (selectedIndex !== null) {
          const newSeasoningSets = [...seasoningSets];
          newSeasoningSets[selectedIndex] = formData;
          setSeasoningSets(newSeasoningSets);
          setIsModified(true);
        }
      };


    
    return (
        <>

    {seasoningSets.map((seasoningSet, index) => (
        <div className={`formset-container ${index === 0 ? 'first-formset' : ''}`} key={index}>
        {index === 0 && (
    <div className="row mb-2">
    <div className={`col-${recipeStatus === 'completed' ? '7' : '4'} p-0 m-0`}>
        <label className={`header-text ${recipeStatus === 'completed' ? 'header-text-shifted' : ''}`}>使用調味料</label>
    </div>
    
    <div className={`col-${recipeStatus === 'completed' ? '4' : '2'} p-0 m-0`}>
        <label className={`header-text ${recipeStatus === 'completed' ? 'header-text-shifted' : ''}`}>使用量</label>
    </div>
        {recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text">単位</label>
    </div>
        )}
        {recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text">結果</label>
    </div>
        )}
{recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}
{recipeStatus === 'adjusting' && (
    <div className="col-4 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}

        {recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text">/次回予定量</label>
    </div>
        )}
        {recipeStatus === 'adjusting' && (
    <div className="col-5 p-0 m-0">
        <label className="header-text">調整履歴</label>
    </div>
        )}
        {recipeStatus === 'adjusting' && (
    <div className="col-1 p-0 m-0">
        <label className="header-text">操作</label>
    </div>
        )}
    </div>
        )}
    
    
    <div className="row mb-3">{/* 使用調味料フォーム */}
    <div className={`col-${recipeStatus === 'completed' ? '7' : '4'} p-0 m-0`}>
    {recipeStatus === 'completed' ? (
      <div className="completed-text">{seasoningSet.ingredient}</div>
    ) : (
      isShoppingMode && seasoningSet.ingredient ? (
        <button 
  className="form-control btn btn-primary2 adjustable2-font-size" 
  onClick={() => handleButtonSeasoningClick(seasoningSet.ingredient)}
>
  {seasoningSet.ingredient}
</button>

      ) : (
        <input 
  type="text" 
  className="form-control" 
  id="seasoningInput"
  name="seasoning"
  placeholder="使用調味料" 
  value={seasoningSet.ingredient}
  onClick={() => handleOpenModal(seasoningSet, index, 'condiment')}
  onChange={(e) => handleSeasoningSetChange(e, index, 'ingredient')}
  autoComplete="off"
/>

      )
    )}
  </div>
    
    {/* 使用量フォーム */}
    <div className={`col-2 p-0 m-0 ${recipeStatus === 'completed' ? 'text-right-when-completed' : ''}`}>
        {recipeStatus === 'completed' ? (
        <span>{seasoningSet.amount}</span>
        ) : (
        <input 
            type="number" 
            className="form-control" 
            id="seasoningAmountInput"
            name="seasoningAmount"
            placeholder="使用量" 
            step="0.1"
            min="0"
            value={seasoningSet.amount}
            onChange={(e) => handleSeasoningAmountChange(e, index, seasoningSet)}
        />
        )}
    </div>
    
        {/* 単位フォーム */}
        <div className="col-2 p-0 m-0">
    {recipeStatus === 'completed' ? (
    <div className="completed-text2">{seasoningSet.unit}</div>
        ) : (
        <select 
            className="form-control" 
            id={`seasoningunitSelect-${index}`}
            name="seasoningUnit"
            value={seasoningSet.unit}
            onChange={(e) => handleSeasoningSetChange(e, index, 'unit')}
        >
        {unitOptions.map((unit, unitIndex) => (
            <option key={unitIndex} value={unit || ""}>{unit || '単位'}</option>
        ))}
        </select>
        )}
    </div>
    
        {/* 結果フォーム */}
        {recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">{/* 調味料の結果フォーム */}
        <select 
             id={`seasoningresultInput-${index}`}
            className="form-control smaller-text" 
            value={seasoningSet.result}
            onChange={(e) => handleSeasoningSetChange(e, index, 'result')}
        >
        {resultOptions.map((result, resultIndex) => (
                <option key={resultIndex} value={result || ""}>{result || '結果'}</option>
            ))}
        </select>
    </div>
        )}

{recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}
{recipeStatus === 'adjusting' && (
    <div className="col-4 p-0 m-0">
        <label className="header-text"></label>
    </div>
)}



    
        {/* 次回予定量 */}
        {recipeStatus === 'adjusting' && (
    <div className="col-2 p-0 m-0"> {/* 次回予定量フォーム */}
        <input 
            type="number" 
            className="form-control" 
            id="seasoningNextUsageAmountInput"
            name="nextUsageAmount"
            placeholder="次回予定" 
            step="0.1"  // 0.1単位での変化を許可
            min="0"
            value={seasoningSet.nextUsageAmount}
            onChange={(e) => handleSeasoningSetChange(e, index, 'nextUsageAmount')}
    
        />
    </div>
        )}
        
        {recipeStatus === 'adjusting' && (
    <div className="col-5 p-0 m-0">{/* 調整履歴フォーム */}
        <input 
            type="text" 
            className="form-control" 
            id="seasoningAdjustmentHistoryInput"
            name="adjustmentHistory"
            placeholder="調整履歴" 
            value={seasoningSet.adjustmentHistory}
            onChange={(e) => handleSeasoningSetChange(e, index, 'adjustmentHistory')}
        />
    </div>
        )}
    
        {recipeStatus === 'adjusting' && (
    <div className="col-1 d-flex align-items-center">{/* 削除ボタン */}
        <button className="btn btn-danger small-text-btn no-wrap-btn" onClick={(e) => {
            if (window.confirm("削除しますか？")) {
            handleRemoveSeasoningSet(index);
        }
        }}>削除</button>
    </div>
        )}
    </div>
        {recipeStatus === 'adjusting' && <hr />}
    </div> 
        ))}
    
        {recipeStatus === 'adjusting' && (
    <div className="row mt-4">
    <div className="col p-0 m-0">{/* 調味料追加ボタン */}
        <button className="btn btn-success mt-1 mb-3 small-text-btn" onClick={handleAddSeasoningSet}>調味料追加+</button>
    </div>
    <MyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        initialData={selectedSeasoningSet}
        unitOptions={unitOptions}
        resultOptions={resultOptions}
        modalType='condiment'
      />


    </div>
        )}
    
</>
    );
    };

export default RecipeForm3;       