import React, { useState, useEffect } from 'react';

import mystyles from './MyModal.module.css';
import ReactModal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyModal = ({ isOpen, onClose, onSubmit, initialData, unitOptions, resultOptions, modalType }) => {
  console.log('MyModal rendered with modalType:', modalType);  // 追加
  const [formData, setFormData] = useState(initialData || {
      ingredient: '',
      amount: '',
      unit: '',
      result: '',
      nextUsageAmount: '',
      adjustmentHistory: ''
    });
  
    const handleChange = (e) => {
      console.log("handleChange called with:", e.target.name, e.target.value);  // この行を追加
      const { name, value } = e.target;
      if (name === 'amount' && formData.nextUsageAmount !== '0' && formData.nextUsageAmount !== '') {
        if (window.confirm("次回予定量をクリアして変更します。よろしいですか？")) {
          setFormData({
            ...formData,
            nextUsageAmount: '0',
            [name]: value
          });
        } else {
          return;
        }
      } else {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    };
     
    useEffect(() => {
      console.log("Initial data in MyModal:", initialData);  // このログを追加
      if (initialData) {  // この条件を追加
        setFormData(initialData);
      }
    }, [initialData]);
      
    const handleFormSubmit = (e) => {
      e.preventDefault();
      console.log("handleFormSubmit called with:", formData);  // この行を追加
      onSubmit(formData);
    }; 

    

    useEffect(() => {
      const handleScroll = () => {
          const modalBackground = document.querySelector('.modal-background');
          if (modalBackground) {
              modalBackground.style.top = `${window.scrollY}px`;
          }
      };  
      window.addEventListener('scroll', handleScroll); 
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);
  
  


  return (
    <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    className="modal-dialog"
    contentLabel="入力フォーム"
    style={{
        overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            flexDirection: 'column',
        },
        content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '500px',
            width: '100%',
            height: '80%',
            overflow: 'auto',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            minHeight: '00px',
            margin: 'auto',
        }
    }}
>
<div className="modal-content" style={{width: '100%',}}>
    
        <div className="modal-header">
        <h5 className="modal-title">{modalType === 'ingredient' ? '使用食材入力' : '使用調味料入力'}</h5>
        </div>
        <div className="modal-body">
            <form onSubmit={handleFormSubmit}>
                <div className="form-group row">
                <label htmlFor="ingredient" className="col-12 text1">{modalType === 'ingredient' ? '使用食材' : '使用調味料'}</label>
                    <div className="col-12">
                        <input
                            type="text"
                            className={`${mystyles['form-control']} ${mystyles.form1}`}
                            id="ingredient"
                            name="ingredient"
                            value={formData.ingredient}
                            onChange={handleChange}
                            placeholder={modalType === 'ingredient' ? '使用食材' : '使用調味料'}
                        />
                    </div>
                </div>
                　
                <div className="form-group row">
                    <label htmlFor="amount" className="col-7 text2">使用量</label>
                    <label htmlFor="unit" className="col-5 text3">単位</label>
                
                    <div className="col-7">
                        <input
                            type="number"
                            className={`${mystyles['form-control']} ${mystyles.form1}`}
                            id="amount"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            placeholder="使用量"
                        />
                    </div>
                    <div className="col-5">
                        <select
                            className={`${mystyles['form-control']} ${mystyles.form1}`}
                            id="unit"
                            name="unit"
                            value={formData.unit}
                            onChange={handleChange}
                        >
                            {unitOptions.map((unit, index) => (
                                <option key={index} value={unit}>{unit ? unit : '選択'}</option>
                            ))}
                        </select>
                    </div>
                </div>
                　                        
                <div className="form-group row">
                    <label htmlFor="nextUsageAmount" className="col-7 text4">次回予定量</label>
                    <label htmlFor="result" className="col-5 text5">結果</label>
                    <div className="col-7">
                        <input
                            type="number"
                            className={`${mystyles['form-control']} ${mystyles.form1}`}
                            id="nextUsageAmount"
                            name="nextUsageAmount"
                            value={formData.nextUsageAmount}
                            onChange={handleChange}
                            placeholder="次回予定量"
                        />
                    </div>
                
               
                    
                    <div className="col-5">
                        <select
                            className={`${mystyles['form-control']} ${mystyles.form1}`}
                            id="result"
                            name="result"
                            value={formData.result}
                            onChange={handleChange}
                        >
                            {resultOptions.map((result, index) => (
                                <option key={index} value={result}>{result ? result : '選択'}</option>
                            ))}
                        </select>
                        </div>
                </div>
                　
                <div className="form-group row">
                    <label htmlFor="adjustmentHistory" className="col-12 text6">調整履歴</label>
                    <div className="col-12">
                        <input
                            type="text"
                            className={`${mystyles['form-control']} ${mystyles.form1}`}
                            id="adjustmentHistory"
                            name="adjustmentHistory"
                            value={formData.adjustmentHistory}
                            onChange={handleChange}
                            placeholder="調整履歴"
                        />
                    </div>
                </div>
            </form>
        </div>
        　
        <div className="modal-footer">      
            <button type="button" className="btn btn-primary okbtn" onClick={() => {
                onSubmit(formData);
                onClose();
            }}>　OK　</button>
            <button type="button" className="btn btn-secondary canbtn" onClick={onClose}>キャンセル</button>
        </div>
    </div>
</ReactModal>

);
  
};

export default MyModal;