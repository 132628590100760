import React, { useState, useEffect, useRef } from 'react'; 
import ReactModal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RecipeForm4.css';


const RecipeForm4 = ({ procedure: initialProcedure, recipeStatus, setProcedure }) => { 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempProcedure, setTempProcedure] = useState('');
    const [localProcedure, setLocalProcedure] = useState(initialProcedure);  // 名前を変更しました
    const textAreaRef = useRef(null);  // テキストエリアへの参照を作成
    const modalTextAreaRef = useRef(null);  // モーダルのテキストエリアへの参照を作成
    
    


    


    const updateModalTextareaHeight = () => {
        if (modalTextAreaRef.current) {
            modalTextAreaRef.current.style.height = 'auto';
            modalTextAreaRef.current.style.height = modalTextAreaRef.current.scrollHeight + 'px';
        }
    };

    const handleModalTextareaChange = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = (e.target.scrollHeight) + 'px';
        setTempProcedure(e.target.value);
    };

    useEffect(() => {
        if (isModalOpen) {
            updateModalTextareaHeight();  // モーダルが表示されるたびにテキストエリアの高さを更新
        }
    }, [isModalOpen]);



    const handleOpenModal = () => {
        setTempProcedure(localProcedure);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSave = () => {
        setProcedure(tempProcedure);
        setIsModalOpen(false);  // モーダルを閉じる
        
    };
    
    
    
    useEffect(() => {
        const textArea = textAreaRef.current;
        textArea.style.height = 'auto';  // 一旦テキストエリアの高さをリセット
        textArea.style.height = textArea.scrollHeight + 'px';  // スクロールの高さに合わせてテキストエリアの高さを再設定
    }, [localProcedure]);  // procedureステートが変更されるたびにこのエフェクトを実行

    useEffect(() => {
        setLocalProcedure(initialProcedure);
    }, [initialProcedure]);
    

    
    
    
    

    return (
        <div className="row">
            
            <div className={`col-${recipeStatus === 'completed' ? '4' : '2'} p-0 m-0`}>
    <label className={`header-text ${recipeStatus === 'completed' ? 'header-text-shifted' : ''}`}>調理手順・備考</label>
    </div>
            <textarea 
                    ref={textAreaRef}  // 作成したrefをテキストエリアに割り当て
                    className="procedure-textarea form-control" 
                    rows="1"
                    placeholder="手順や備考を入力してください..."
                    value={localProcedure}
                    readOnly  // モーダルが表示されるまでテキストエリアを読み取り専用にします
                    onClick={handleOpenModal}  // テキストエリアをクリックしたときにモーダルを開きます
                />
                
            
            
            <ReactModal
                isOpen={isModalOpen}
                onAfterOpen={updateModalTextareaHeight}  // モーダルが開いた後に関数を呼び出す
                onRequestClose={handleCloseModal}
                className="modal-dialog"
                contentLabel="入力フォーム"
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflowY: 'auto',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        flexDirection: 'column',
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        maxWidth: '500px',
                        width: '100%',
                        height: '80%',
                        overflow: 'auto',
                        padding: '20px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                        minHeight: '300px',
                        margin: 'auto',
                    }
                }}
            >
                <div className="modal-content" style={{ width: '100%', }}>
                    <div className="modal-header">
                        <h5 className="modal-title">調理手順・備考</h5>                       
                    </div>
                    <div className="modal-body">
                    <textarea
                            ref={modalTextAreaRef}  // 作成したrefをテキストエリアに割り当て
                            className="form-control form2"
                            value={tempProcedure}
                            onChange={handleModalTextareaChange}
                            placeholder="調理手順や備考など自由入力"
                            style={{ resize: 'vertical' }}
                        />

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" onClick={handleCloseModal}>
                            キャンセル
                        </button>
                        <button className="btn btn-primary" onClick={handleSave}>
                            OK
                        </button>
                    </div>
                </div>
            </ReactModal>
            
        </div>
    );
};

export default RecipeForm4;

