import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Linkをインポート
import { Auth } from 'aws-amplify';

function ProfilePage() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(''); // ユーザーIDの状態

  // コンポーネントのマウント時にユーザー情報を取得
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUserId(userInfo.attributes.sub); // ユーザーIDを状態に設定
      } catch (error) {
        console.error('ユーザー情報の取得エラー', error);
      }
    };

    fetchUser();
  }, []);

  // ホームページに戻る処理
  const handleBackToHome = () => {
    navigate('/'); // ルートページ（ホーム）に戻る
  };

  // ログアウト処理
  const handleLogout = async () => {
    try {
      await Auth.signOut(); // Cognitoからログアウト
      navigate('/login');   // ログインページにリダイレクト
    } catch (error) {
      console.error('ログアウトエラー', error);
    }
  };

  return (
    <div>
      <h1>myプロフィール</h1>
      <p>ここであなたのプロフィールを管理できます。</p>
      {userId && <p>ユーザーID: {userId}</p>} {/* ユーザーIDの表示 */}
      <button onClick={handleBackToHome}>ホームへ戻る</button>
      <button onClick={handleLogout}>ログアウト</button>
      {/* プライバシーポリシーへのリンクを追加 */}
      <div>
        <Link to="/privacy-policy">プライバシーポリシー</Link>
      </div>
    </div>
  );
}

export default ProfilePage;


