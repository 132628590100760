// tenshino.omamori/src/App.js
import React, { useState, useEffect } from 'react'; // useEffect をインポート
import './App.css';
import { Route, Routes, useParams } from 'react-router-dom';
import RecipeForm from './RecipeForm';
import TopPage from './TopPage';
import RecipeDetail from './RecipeDetail';
import ShoppingList from './ShoppingList';
import CommunityPage from './CommunityPage'; // CommunityPageをインポート
import SubmitRecipePage from './SubmitRecipePage'; // SubmitRecipePageをインポート
import ReceiveRecipePage from './ReceiveRecipePage'; // ReceiveRecipePageをインポート
import ProfilePage from './ProfilePage'; // ProfilePageをインポート
import ContactPage from './ContactPage'; // ContactPageをインポート
import DonatePage from './DonatePage'; // DonatePageをインポート
import { UserProvider } from './UserContext';
import { useNavigate } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

import 'bootstrap/dist/css/bootstrap.min.css';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import ForgotPassword from './Auth/ForgotPassword';
import NewTopPage from './NewTopPage';
import { Auth } from 'aws-amplify';

import Home from './Home';
import AmazonRedirect from './AmazonRedirect'; // 適切なパスを指定




function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  



  // ショッピングリスト用の状態
  const [shoppingList, setShoppingList] = useState([]);

  // アイテム追加関数
  const handleAddItem = (newItem) => {
    console.log("newItem in handleAddItem:", newItem); // この行を追加
    setShoppingList(prevList => {
      const existingItemIndex = prevList.findIndex(item => item.name === newItem.name);
      console.log("existingItemIndex:", existingItemIndex); // この行を追加
      if (existingItemIndex !== -1) {
        // 既存のアイテムの数量を増やす
        const newList = [...prevList];
        newList[existingItemIndex].quantity += 1;
        return newList;
      } else {
        // 新しいアイテムを追加
        return [...prevList, { ...newItem, quantity: 1 }];
      }
    });
  };
  

  // ここにuseEffectを追加
  useEffect(() => {
    console.log("Current shopping list:", shoppingList);
  }, [shoppingList]);


 
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (e) {
        setIsAuthenticated(false);
        // リダイレクトの条件を更新
        if (!['/signin', '/signup', '/forgotpassword', '/privacy-policy'].includes(window.location.pathname)) {
          // それ以外のページの場合、NewTopPageにリダイレクト
          navigate('/');
        }
      }
    };
  
    checkAuthentication();
  }, [navigate]);
  
  
  



  return (
    <UserProvider>
      <div className="App">
      <Routes>
  {/* 認証に依存しない汎用ルート */}
  <Route path="/signup" element={<SignUp />} />
  <Route path="/signin" element={<SignIn />} />
  <Route path="/forgotpassword" element={<ForgotPassword />} />
  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  <Route path="/amazon-redirect" element={<AmazonRedirect />} />
  {/* 認証されたユーザー専用のルート */}
  {isAuthenticated && (
    <>
      <Route path="/recipe-form/:id" element={<RecipeWithData handleAddItem={handleAddItem} />} />
      <Route path="/recipe-form" element={<RecipeForm handleAddItem={handleAddItem} />} />
      <Route path="/recipe/:id" element={<RecipeDetail />} />
      <Route path="/shopping-list" element={<ShoppingList shoppingList={shoppingList} setShoppingList={setShoppingList} />} />
      <Route path="/top" element={<TopPage />} />
      <Route path="/home" element={<Home />} />
      <Route path="/community" element={<CommunityPage />} />
      <Route path="/submit-recipe" element={<SubmitRecipePage />} />
      <Route path="/receive-recipes" element={<ReceiveRecipePage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/donate" element={<DonatePage />} />
    </>
  )}

  {/* ルートページのルート */}
  <Route path="/" element={isAuthenticated ? <Home /> : <NewTopPage />} />
</Routes>

      </div>
    </UserProvider>
  );
}

function RecipeWithData({ handleAddItem }) {
  const { id } = useParams();
  const recipes = JSON.parse(localStorage.getItem('recipes') || '[]');
  const recipeData = recipes.find(r => r.id === id);

  return <RecipeForm recipeData={recipeData} handleAddItem={handleAddItem} />;
}

export default App;











