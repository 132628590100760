import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RecipeForm.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import RecipeForm2 from './RecipeForm2';
import RecipeForm3 from './RecipeForm3';
import RecipeForm4 from './RecipeForm4';
import RecipeForm5 from './RecipeForm5';
import RecipeForm6 from './RecipeForm6';
import RecipeForm7 from './RecipeForm7';  // Import the RecipeForm7 component
import { uploadFileToS3 } from './utils/s3';

function RecipeForm({ handleAddItem,recipeData = {} }) {
    
    const { id } = useParams();
    console.log("ID from useParams:", id);
    const procedureRef = useRef(null);
    const [isModified, setIsModified] = useState(false);
    const navigate = useNavigate();   
    const [isShoppingMode, setIsShoppingMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

    
    const unitOptions = [
        "", "g", "ml", "個", "大さじ", "小さじ", "枚", "本", "杯", "㎏",
        "L", "包", "切れ", "玉", "筋", "カップ", "mg", "cc", "株", "房", "片"
    ];
    const resultOptions = [
        "", "凄く多い", "割と多い", "多い", "やや多い", "気持ち多い", "ちょうどいい", 
        "気持ち少ない", "やや少ない", "少ない", "割と少ない", "凄く少ない"
    ];
    const categoryOptions = [
        "牛肉料理", "豚肉料理", "鶏肉料理", "魚料理", "貝類",
        "卵料理", "簡単料理", "時短料理", "揚げ物", "炒め物",
        "煮物", "蒸し物", "焼き物", "サラダ", "ご飯もの",
        "麺料理", "汁物", "和え物", "漬物", "パスタ",
        "カレー", "デザート", "パン", "鍋料理", "その他"
    ];  
   
    const [loadedRecipe, setLoadedRecipe] = useState({});
    const [recipeName, setRecipeName] = useState(() => recipeData.name || "");
    const [recipeStatus, setRecipeStatus] = useState(recipeData.status || 'adjusting');
    const [formSets, setFormSets] = useState(recipeData.ingredients || [{
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    }]);
    const [seasoningSets, setSeasoningSets] = useState(recipeData.seasonings || [{
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    }]);
  
    const [resizedImages, setResizedImages] = useState([null, null, null, null]);
    const [selectedCategories, setSelectedCategories] = useState(recipeData.categories || {
        category1: '',
        category2: '',
        category3: '',
        category4: ''
    });

    const [procedure, setProcedure] = useState(recipeData.procedure || '');
    useEffect(() => {
    if (procedureRef.current) {
        procedureRef.current.style.height = 'auto';
         procedureRef.current.style.height = `${procedureRef.current.scrollHeight}px`;
    }
    }, [procedure]);

    useEffect(() => {
    if (id) {
    const storedRecipes = JSON.parse(localStorage.getItem('recipes') || '[]');
    const matchedRecipe = storedRecipes.find(recipe => recipe.id === id) || {};         
        setLoadedRecipe(matchedRecipe);                      
        setRecipeName(matchedRecipe.name || '');         
        setRecipeStatus(matchedRecipe.status || 'adjusting');
        setFormSets(matchedRecipe.ingredients || [{
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    }]);         
        setSeasoningSets(matchedRecipe.seasonings || [{
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    }]);
    }
    }, [id]);

    useEffect(() => {
    if (Object.keys(recipeData).length !== 0) {
        setRecipeName(recipeData.name || '');
        setRecipeStatus(recipeData.status || 'adjusting');
        setFormSets(recipeData.ingredients || [{
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    }]);
        setSeasoningSets(recipeData.seasonings || [{
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    }]);
    }
    }, [recipeData]);

    useEffect(() => {
    if (isModified) {
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = "変更が保存されていません。このページを離れますか？";
    };
    
    const handleBackButton = (e) => {
        e.preventDefault();
        alert('行った編集データは失われました。ページ移動前に必ず保存するようにしてください。');
    };   
        window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener("popstate", handleBackButton);
        return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("popstate", handleBackButton);
    };
    }
    }, [isModified]);
          
    const handleAddIngredient = () => {
    const newFormSet = { 
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
         adjustmentHistory: ''
    };
        setFormSets(prevFormSets => [...prevFormSets, newFormSet]);
    };
    
    const handleRemoveIngredient = (indexToRemove) => {
    const updatedFormSets = formSets.filter((_, index) => index !== indexToRemove);
        setFormSets(updatedFormSets);
    };
    
    const handleFormSetChange = (e, index, fieldName) => {
    const newValue = e.target.value;
        setFormSets(prevFormSets => {
    const updatedFormSets = [...prevFormSets];
        updatedFormSets[index][fieldName] = newValue;
        return updatedFormSets;
    });
        setIsModified(true);  // この行を追加
    };
    
    const handleSave = () => {
        // 使用食材フォームのデータを更新
    const updatedFormSets = formSets.map(formSet => {
    const newHistory = formSet.amount ? 
        (formSet.adjustmentHistory ? 
        `${formSet.adjustmentHistory}, ${formSet.amount}` : 
        formSet.amount) : 
        formSet.adjustmentHistory;
        return { ...formSet, adjustmentHistory: newHistory };
    });
        setFormSets(updatedFormSets);
    
        // 使用調味料フォームのデータを更新
    const updatedSeasoningSets = seasoningSets.map(seasoningSet => {
    const newHistory = seasoningSet.amount ? 
        (seasoningSet.adjustmentHistory ? 
        `${seasoningSet.adjustmentHistory}, ${seasoningSet.amount}` : 
        seasoningSet.amount) : 
        seasoningSet.adjustmentHistory;
        return { ...seasoningSet, adjustmentHistory: newHistory };
        });
        setSeasoningSets(updatedSeasoningSets);
        // 手順、画像、カテゴリーの状態を現在の値で更新
        setProcedure(procedure);
        setResizedImages(resizedImages);
        setSelectedCategories(selectedCategories);
    };
        
    const handleStatusChange = () => {
        setRecipeStatus(prevStatus => prevStatus === 'completed' ? 'adjusting' : 'completed');
        setIsModified(true); // この行を追加
    };
    
    const handleSaveWithConfirmation = () => {
        if (window.confirm("使用量を調整履歴に反映します。よろしいですか？")) {
        handleSave();
    }
    };
    
    const handleRemoveSeasoningSet = (index) => {
    const newSeasoningSets = [...seasoningSets];
        newSeasoningSets.splice(index, 1);
        setSeasoningSets(newSeasoningSets);
    };
    
    const initialSeasoningSet = { 
        ingredient: '', 
        amount: '', 
        unit: '', 
        result: '',
        nextUsageAmount: '',
        adjustmentHistory: ''
    };

    const handleAddSeasoningSet = () => {
        setSeasoningSets(prevSets => [...prevSets, initialSeasoningSet]);        
    };
    
    const handleSeasoningSetChange = (e, index, fieldName) => {
    const newValue = e.target.value;
        setSeasoningSets(prevSets => {
    const updatedSets = [...prevSets];
        updatedSets[index][fieldName] = newValue;
        return updatedSets;
    });
        setIsModified(true);  // この行を追加
    };
    
    const handleSeasoningAmountChange = (e, index, currentSet) => {
    
        if (currentSet.nextUsageAmount !== '0' && currentSet.nextUsageAmount !== '') {
        if (window.confirm("次回予定量をクリアして変更します。よろしいですか？")) {
    const newSeasoningSets = [...seasoningSets];
        newSeasoningSets[index].nextUsageAmount = '0';
        setSeasoningSets(newSeasoningSets);
    } else {
        return;
    }}
        handleSeasoningSetChange(e, index, 'amount');
    };
    
    const handleIngredientAmountChange = (e, index, currentSet) => {
   
        if (currentSet.nextUsageAmount !== '0' && currentSet.nextUsageAmount !== '') {
        if (window.confirm("次回予定量をクリアして変更します。よろしいですか？")) {
    const newFormSets = [...formSets];
        newFormSets[index].nextUsageAmount = '0';
        setFormSets(newFormSets);
    } else {
        return;
    }}
        handleFormSetChange(e, index, 'amount');
    };
    
    const handleProcedureChange = (e) => {
        e.target.style.height = 'auto';  // 一旦テキストエリアの高さをリセット
        e.target.style.height = (e.target.scrollHeight) + 'px';  // スクロールの高さに合わせてテキストエリアの高さを再設定      
        setProcedure(e.target.value);  
        setIsModified(true);      
    }
    
    const handleDeleteConfirmation = () => {
        const isConfirmed = window.confirm("このレシピをすべて削除します。よろしいですか？");
        if (isConfirmed) {
        // ここで実際の削除処理を行う
        setFormSets([{
            ingredient: '', 
            amount: '', 
            unit: '', 
            result: '',
            nextUsageAmount: '',
            adjustmentHistory: ''
    }]);
        setRecipeStatus('adjusting');
    }
    };
           
    const resizeImage = (file, maxWidth, callback) => {
    const reader = new FileReader();
        reader.onload = function(event) {
    const img = new Image();
        img.src = event.target.result;
        img.onload = function() {
    const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
        if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
    }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(callback, 'image/jpeg', 0.8);
    };
    };
        reader.readAsDataURL(file);
    };
    
    const handleResizedImageChange = (e, index) => {
    const file = e.target.files[0];
        if (file) {
        resizeImage(file, 800, (resizedBlob) => {
    const updatedImages = [...resizedImages];
        updatedImages[index] = resizedBlob;
        setResizedImages(updatedImages);
    });
        setIsModified(true);
    }};
       
    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    const handleCategoryChange = (event, categoryNumber) => {
    const value = event.target.value;
        setSelectedCategories(prevState => ({
        ...prevState,
        [categoryNumber]: value
    }));
        setIsModified(true);  // この行を追加
    };
    





    

    async function handleSaveRecipe(userId) {
    
    console.log("handleSaveRecipe userId:", userId);

    const existingRecipes = JSON.parse(localStorage.getItem('recipes') || '[]');
    const currentDate = new Date().toISOString();    
    let currentId = id;
    console.log("Current ID before checking:", currentId);  

    // id が未定義の場合のみ新しいIDを生成
    if (!currentId) {
        currentId = userId + "-" + Date.now().toString();
    }
    if (!userId || !currentId) {
        console.error("Error: Missing userId or currentId");
        return; // 処理を中断
    }
        console.log("Current ID after checking:", currentId);
    // S3へのアップロード処理
    // テキストデータのアップロード
    const textFileContent = new Blob([procedure], { type: 'text/plain' });
    const textFileName = `users/${userId}/${currentId}-procedure.txt`; // ユーザー毎のディレクトリに保存
        console.log("Generated file name:", textFileName);
        await uploadFileToS3(textFileContent, 'tenmori.usersbucket', textFileName);

    // 画像データのアップロード
    for (const image of resizedImages) {
    const imageFileName = `users/${userId}/${currentId}-image.jpg`; // ユーザー毎のディレクトリに保存
        await uploadFileToS3(image, 'tenmori.usersbucket', imageFileName);
    }
    // 現在のレシピデータを作成
    const currentRecipe = {
        id: currentId,
        name: recipeName,
        status: recipeStatus,
        ingredients: formSets,
        seasonings: seasoningSets,
        procedure: procedure,   // 調理手順のステートを追加
        images: resizedImages,  // 画像のステートを追加
        categories: selectedCategories,  // カテゴリーのステートを追加
        createdAt: loadedRecipe.createdAt || currentDate,  // 既に作成日があればそのまま、なければ現在の日付をセット
        updatedAt: currentDate  // 更新日は常に現在の日付をセット
    };   
    // 既存のレシピの中で同じIDのものを探す
    const existingRecipeIndex = existingRecipes.findIndex(recipe => recipe.id === currentId);
    // 既存のレシピが見つかった場合、その位置に上書き
    if (existingRecipeIndex !== -1) {
        existingRecipes[existingRecipeIndex] = currentRecipe;
    } else {
    // 既存のレシピが見つからなかった場合、新しいレシピとして追加
        existingRecipes.push(currentRecipe);
    }
    // ローカルストレージに保存
        localStorage.setItem('recipes', JSON.stringify(existingRecipes));
        setIsModified(false);
        setIsSaveModalOpen(true); // この行を修正

    }
  


    const handleSaveModalClose = () => {
        setIsSaveModalOpen(false); // モーダルを閉じる 
    };
    const handleSaveModalOpen = () => {
    const userId = localStorage.getItem('userId'); // ローカルストレージから userId を取得
        console.log("handleSaveModalOpen userId:", userId);
         handleSaveRecipe(userId);  // userId を引数として渡す
        setIsSaveModalOpen(true);
    };




    const handleRecipeNameChange = (e) => {      
        setRecipeName(e.target.value);
        setIsModified(true);       
    };
    
    const handleBackToTop = (event) => {       
        if (isModified) {
    const isConfirmed = window.confirm("変更内容が保存されていません。本当に移動しますか？\n（保存ボタンはページの一番下にあります。）");            
        if (!isConfirmed) {
        event.preventDefault();
        return;
    }}
        navigate("/Top");
    }

    // 追加: 買い物追加モードをトグルする関数
const toggleShoppingMode = () => {
    setIsShoppingMode(!isShoppingMode);
  };

  const handleProcedureSubmit = (newProcedure) => {
    setProcedure(newProcedure);  // 新しい procedure をステートに保存
};












return (
             
<div className="container remove-margin">              
    <h2>レシピフォーム</h2>        
    <p className="recipe-dates">
        作成日：{new Date(loadedRecipe.createdAt || "").toLocaleDateString('ja-JP')}　
        最終更新日：{new Date(loadedRecipe.updatedAt || "").toLocaleDateString('ja-JP')}
    </p>
   
<div className="row mb-3 remove-margin-bottom align-items-center">
    <label className="col-2 col-form-label recipe-name-label">料理名</label>
<div className="col-9">
    <input 
        type="text" 
        className="form-control larger-input" 
        id="recipeName" 
        placeholder="料理名を入力"
        value={recipeName} // 追加：valueをstateと紐づけ
        onChange={handleRecipeNameChange}
    />
</div>
</div>

<div className="row">
    <div className="col-6">
        <button onClick={handleBackToTop} className="btn greenbtn recipegreenbtn1 adjustable-font-size">myキッチンへ戻る</button>
    </div>
    <div className="col-6 d-flex justify-content-center recipegreenbtn2 adjustable-font-size">
        <div className={`toggle-container ${recipeStatus === 'completed' ? 'active' : ''}`} onClick={handleStatusChange}>
            <div className="toggle-slider"></div>
            <div className="toggle-label">調整中</div>
            <div className="toggle-label">完成</div>
        </div>
    </div>
    </div>
    <div className="row">
    {recipeStatus === 'adjusting' && (
        <>
            <div className="col-4">
                <button className="btn greenbtn recipegreenbtn3 adjustable-font-size" onClick={handleSaveWithConfirmation}>履歴保存</button>
            </div>
            <div className="col-4">
                <button className="btn greenbtn recipegreenbtn4 adjustable-font-size" onClick={toggleShoppingMode}>
                    {isShoppingMode ? "　  完了  　" : "買い物追加"}
                </button>
            </div>
            <div className="col-4">
                <button className="btn btn-danger reciperedbtn1 adjustable-font-size" onClick={handleDeleteConfirmation}>レシピ削除</button>
            </div>
        </>
    )}
</div>




    {recipeStatus !== 'adjusting' && <hr />}{/* トグル完成時に表示 */}
    {recipeStatus === 'adjusting' && <hr />}{/* トグル完成時に非表示 */}
                
    {/* 食材フォームセット（2から） */}
    <RecipeForm2 
        formSets={formSets} 
        setFormSets={setFormSets}
        recipeStatus={recipeStatus} 
        handleFormSetChange={handleFormSetChange} 
        handleIngredientAmountChange={handleIngredientAmountChange} 
        unitOptions={unitOptions} 
        resultOptions={resultOptions} 
        handleRemoveIngredient={handleRemoveIngredient} 
        handleAddIngredient={handleAddIngredient} 
        isShoppingMode={isShoppingMode}
        handleAddItem={handleAddItem}
        isModified={isModified}
        setIsModified={setIsModified}
    />
 
    {recipeStatus !== 'adjusting' && <hr />}{/* トグル完成時に表示 */}
    {recipeStatus === 'adjusting' && <hr />}{/* トグル完成時に非表示 */}

    
    {/* 調味料フォームセット（3から） */}
    <RecipeForm3 
        setSeasoningSets={setSeasoningSets}
        seasoningSets={seasoningSets}
        recipeStatus={recipeStatus}
        handleSeasoningSetChange={handleSeasoningSetChange}
        handleSeasoningAmountChange={handleSeasoningAmountChange}
        unitOptions={unitOptions}
        resultOptions={resultOptions}
        handleRemoveSeasoningSet={handleRemoveSeasoningSet}
        handleAddSeasoningSet={handleAddSeasoningSet}
        isShoppingMode={isShoppingMode}
        handleAddItem={handleAddItem}
        isModified={isModified}
        setIsModified={setIsModified}
    />

    {recipeStatus !== 'adjusting' && <hr />}{/* トグル完成時に表示 */}

    {/* 調理手順・備考フォーム（4から） */}
    <RecipeForm4 
        procedure={procedure} 
        handleProcedureChange={handleProcedureChange} 
        procedureRef={procedureRef} 
        onProcedureSubmit={handleProcedureSubmit}
        setProcedure={setProcedure}
        recipeStatus={recipeStatus}
    />

    {/* 画像フォーム（5から） */}
    <RecipeForm5 
        resizedImages={resizedImages} 
        handleResizedImageChange={handleResizedImageChange} 
        isModalOpen={isModalOpen} 
        closeModal={closeModal} 
        openModal={openModal}
    />

    {/* カテゴリーフォーム（6から） */}
    <RecipeForm6 
        selectedCategories={selectedCategories} 
        handleCategoryChange={handleCategoryChange} 
        categoryOptions={categoryOptions} 
    />
    
    {/* 保存ボタン */}
    <div className="row mb-3 remove-margin-bottom align-items-center center-align">
    <div className="col-2 offset-6">
    <button 
            onClick={handleSaveModalOpen} 
            className="btn save-button adjustable-font-size"
        >
            保存する
        </button>
    </div>
</div>

<RecipeForm7 
            openModal={handleSaveModalOpen} 
            isModalOpen={isSaveModalOpen} 
            closeModal={handleSaveModalClose} 
        />    
      
    </div>

);
};
export default RecipeForm;
