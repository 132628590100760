import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';  // 追加
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify } from 'aws-amplify';  // 修正
import awsconfig from './aws-exports';
import { I18n } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
// 日本語の翻訳を設定する
I18n.putVocabulariesForLanguage('ja', {
  'Sign In': 'サインイン',
  'Sign Up': 'サインアップ',
  'Sign Out': 'サインアウト',
  // 必要な翻訳を追加...
});

// アプリケーションのデフォルト言語を日本語に設定
I18n.setLanguage('ja');


Amplify.configure(awsconfig);
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

ReactModal.setAppElement('#root');



