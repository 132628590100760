import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TopPage.css';
import { UserContext } from './UserContext'; // UserContextの実際のパスを指定
import { deleteFileFromS3 } from './utils/s3'; // 正しいパスを指定してください

function TopPage() {
  const { userId } = useContext(UserContext); // コンポーネントのトップレベルで useContext を使用
  const [category, setCategory] = useState("all"); // カテゴリーの初期値を設定
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    console.log("Current User ID in TopPage:", userId);
    const storedRecipes = JSON.parse(localStorage.getItem('recipes') || '[]');
    setRecipes(storedRecipes);
}, [userId]);

const handleDelete = async (id) => {
  console.log("Deleting recipe with ID:", id); // 削除処理の開始をログに出力
  try {
    const A = id.split('-').slice(0, 5).join('-');
    const filePath = `users/${A}/${id}`;

    console.log(`Deleting file: ${filePath}-procedure.txt from S3`);
    // テキストファイルの削除
    await deleteFileFromS3('tenmori.usersbucket', `${filePath}-procedure.txt`);

    console.log(`Deleting file: ${filePath}-image.jpg from S3`);
    // 画像ファイルの削除
    await deleteFileFromS3('tenmori.usersbucket', `${filePath}-image.jpg`);

    // ローカルのレシピデータの更新
    const updatedRecipes = recipes.filter(recipe => recipe.id !== id);
    setRecipes(updatedRecipes);
    localStorage.setItem('recipes', JSON.stringify(updatedRecipes));

    console.log('ファイルとローカルデータが正常に削除されました');

  } catch (error) {
    console.error('削除処理中にエラーが発生しました', error);
  }
};





  const createNewRecipe = () => {
    console.log("Current User ID:", userId); // ユーザーIDの確認
   // if (!userId) {
   //   console.error("User ID is not defined.");
      // 必要に応じてエラーハンドリング
   //   return;
 // }
    const newId = userId + "-" + Date.now().toString();
    console.log("Redirecting to URL:", `/recipe-form/${newId}`);
    return `/recipe-form/${newId}`;
  }

  const handleDeleteWithConfirmation = (id) => {
    console.log("Trying to delete recipe with ID:", id); // IDをログに出力
    if (window.confirm("本当に削除しますか？")) {
        handleDelete(id);
    }
};





useEffect(() => {
  const storedRecipes = JSON.parse(localStorage.getItem('recipes') || '[]');
  let displayedRecipes = [...storedRecipes];  // storedRecipes のコピーを作成

  if (category === "all") {
      // 新しい順にソート
      displayedRecipes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (category === "newest" || category === "oldest") {
      displayedRecipes = storedRecipes;
  } else {
      // カテゴリに基づくフィルタリング
      displayedRecipes = displayedRecipes.filter(recipe =>
          recipe.categories.category1 === category ||
          recipe.categories.category2 === category ||
          recipe.categories.category3 === category ||
          recipe.categories.category4 === category ||
          recipe.status === category
      );
  }

  // 作成日に基づく並び替え
  if (category === "newest") {
      displayedRecipes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (category === "oldest") {
      displayedRecipes.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  }

  setRecipes(displayedRecipes);
  console.log('displayedRecipes:', displayedRecipes);  // ログに displayedRecipes を出力
}, [category]);



  return (
    <div className="toppagecontainer">
    <div className="top-page topcontainer">
      <h1>myキッチン</h1>

      <div className="row justify-content-center"> {/* 中央寄せのクラスを追加 */}

{/* ホームへ移動ボタン */}
<div className="col-3">
        <Link to="/home" className="btn topfont-size topgreenbtn">ホームへ戻る</Link>  
</div>
<div className="col-1 p-0 m-0">
        <label className="header-text"></label>
    </div>
        {/* カテゴリードロップダウン */}
        <div className="col-3">
        <select value={category} onChange={e => setCategory(e.target.value)} className="topform-control">
    <option value="">カテゴリー検索</option>
    <option value="newest">作成日の新しい順</option>
    <option value="oldest">作成日の古い順</option>
    <option value="completed">完成</option>
    <option value="adjusting">調整中</option>
    <option value="牛肉料理">牛肉料理</option>
    <option value="豚肉料理">豚肉料理</option>
    <option value="鶏肉料理">鶏肉料理</option>
    <option value="魚料理">魚料理</option>
    <option value="貝類">貝類</option>
    <option value="卵料理">卵料理</option>
    <option value="簡単料理">簡単料理</option>
    <option value="時短料理">時短料理</option>
    <option value="揚げ物">揚げ物</option>
    <option value="炒め物">炒め物</option>
    <option value="煮物">煮物</option>
    <option value="蒸し物">蒸し物</option>
    <option value="焼き物">焼き物</option>
    <option value="サラダ">サラダ</option>
    <option value="ご飯もの">ご飯もの</option>
    <option value="麺料理">麺料理</option>
    <option value="汁物">汁物</option>
    <option value="和え物">和え物</option>
    <option value="漬物">漬物</option>
    <option value="パスタ">パスタ</option>
    <option value="カレー">カレー</option>
    <option value="デザート">デザート</option>
    <option value="パン">パン</option>
    <option value="鍋料理">鍋料理</option>
    <option value="その他">その他</option>
</select>

        </div>
        <div className="col-1 p-0 m-0">
        <label className="header-text"></label>
    </div>
        {/* 新規作成ボタン */}
        <div className="col-3">
          <Link to={createNewRecipe()} className="btn  topfont-size topgreenbtn">新規作成</Link>
        </div>

       
      </div>
　

      <div>
      <h1 className="recipe-title">レシピ一覧</h1>
      {console.log(recipes)}

    <ul className="recipe-list">
      
    {recipes.map(recipe => (
    <li key={recipe.id} className="recipe-list-item">
        <div className="recipe-thumbnail">
        {console.log("Debug: ", recipe.images ? recipe.images[0] : 'images array is null or undefined')}
        {console.log("Debug: ", recipe.images[0])} {/* この行を追加 */}
        
        {recipe.images && recipe.images[0] ? (
  (
    console.log('Type of recipe.images[0]:', typeof recipe.images[0]),
    console.log('Details of recipe.images[0]:', JSON.stringify(recipe.images[0], null, 2))
  ),
  recipe.images[0] ? 
                <img src={URL.createObjectURL(recipe.images[0])} alt={recipe.name} /> :
                <span className="no-image">画像なし</span>  // この部分も追加
            ) : (
                <span className="no-image">画像なし</span>
            )}
        </div>
        
        <div className="recipe-details">
        <div className="categories-container">
    {recipe.categories.category1 && <span className="category-label">{recipe.categories.category1}</span>}
    {recipe.categories.category2 && <span className="category-label">{recipe.categories.category2}</span>}
    {recipe.categories.category3 && <span className="category-label">{recipe.categories.category3}</span>}
    {recipe.categories.category4 && <span className="category-label">{recipe.categories.category4}</span>}
    {recipe.status && <span className="category-label status-label">{recipe.status === 'completed' ? '完成' : '調整中'}</span>}
</div>

    <div className="recipe-link">
        <Link to={`/recipe-form/${recipe.id}`}>{recipe.name}</Link>
    </div>
</div>
        
<button 
  onClick={() => handleDeleteWithConfirmation(recipe.id)} 
  className="btn btn-danger topredbtnfont no-wrap-btn topredbtn ">
  削除
</button>


    </li>
    
))}

    </ul>
</div>


　

      {/* 広告部分 */}
      <div className="advertisement">
        こちらに広告が表示されます。
      </div>
    </div>
    </div>
  );
}

export default TopPage;





