import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // 追加
import './ForgotPassword.css'; // ここでCSSをインポート
import { Link } from 'react-router-dom';

// エラーメッセージの日本語変換
const translateErrorMessage = (message) => {
  const translations = {
    'Username cannot be empty': 'メールアドレスを入力してください。',
    'Password does not conform to policy': 'パスワードはポリシーに従ってください。',
    'Invalid password format': '無効なパスワード形式です。',
    'User does not exist': 'ユーザーが存在しません。',
    'User already exists': 'ユーザーは既に存在しています。',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが間違っています。',
    'An account with the given email already exists.': '指定されたメールアドレスのアカウントは既に存在しています。',
    'CodeMismatchException': '確認コードが一致しません。',
    'Invalid verification code provided, please try again.': '無効な確認コードです。もう一度お試しください。',
    'ExpiredCodeException': '確認コードの有効期限が切れています。',
    'NotAuthorizedException': 'この操作は許可されていません。',
    'Confirmation code cannot be empty': '確認コードを入力してください。',
    'PasswordResetRequiredException': 'パスワードのリセットが必要です。',
    'Username should be an email.': '正しいメールアドレスを入力してください。',
    'UserNotFoundException': 'ユーザーが見つかりません。',
    'TooManyFailedAttemptsException': '試行回数が多すぎます。後ほど再試行してください。',
    'TooManyRequestsException': 'リクエストが多すぎます。しばらくしてから再試行してください。',
    'NetworkError': 'ネットワークエラーが発生しました。接続を確認してください。',
    'User is not confirmed.': 'ユーザーはまだ確認されていません。',
    'Password did not conform with policy: Password not long enough': 'パスワードは半角英数字を混ぜて8文字以上で構成してください。',
    '1 validation error detected': '検証エラーが1件検出されました：パスワードが要件を満たしていません。',
    'Password cannot be empty': 'パスワードを入力してください。',
    'CUSTOM_AUTH is not enabled for the client': 'ログイン設定が正しくありません。',
    'Custom auth lambda trigger is not configured for the user pool.': 'カスタム認証Lambdaトリガーがユーザープールに設定されていません。',
    'Value at \'password\' failed to satisfy constraint': 'パスワードが条件を満たしていません。',
    'Member must satisfy regular expression pattern': '入力値は正規表現パターンを満たす必要があります。',
  };
  const errorKey = Object.keys(translations).find(key => message.includes(key));
  return errorKey ? translations[errorKey] : message;
};

function ForgotPassword() {
  const navigate = useNavigate(); // 追加
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [error, setError] = useState('');

  // 成功または新しい操作の開始時にエラーメッセージをリセットする
  const resetError = () => {
    setError('');
  };

  const handleForgotPassword = async () => {
    resetError(); // 新しいサインアップ試行の前にエラーをリセット
// メールアドレスが空かどうかをチェック
if (!email) {
  setError('Eメールアドレスを入力してください。');
  return; // ここで処理を終了
}
// メールアドレスの形式が正しいかどうかをチェック
if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
  setError('有効なEメールアドレスを入力してください。');
  return; // ここで処理を終了
}


try {
  await Auth.forgotPassword(email);
  setIsConfirmation(true);
  setError(''); // エラーをクリア
} catch (e) {
  setError(translateErrorMessage(e.message || 'パスワードのリセットに失敗しました。'));
}
};

const handleForgotPasswordSubmit = async () => {
  resetError(); // 新しいサインアップ試行の前にエラーをリセット
  // 確認コードまたは新しいパスワードが空でないかチェック
  if (!confirmationCode) {
    setError(translateErrorMessage('Confirmation code cannot be empty'));
    return;
  }
  if (!newPassword) {
    setError(translateErrorMessage('Password cannot be empty'));
    return;
  }

  try {
    await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
    alert('パスワードがリセットされました。サインインページに進んでください。');
    navigate('/signin'); // サインインページにリダイレクト
  } catch (e) {
    setError(translateErrorMessage(e.message || '新しいパスワードの設定に失敗しました。'));
  }
};

  return (
    <div className="forgot-password-container">
      <Form>
        {isConfirmation ? (
          <>
            <FormGroup className="forgotpass-form">
              <Label for="confirmationCode" className="forgotpass-label">確認コード</Label>
              <Input
                className="forgotpass-input"
                type="text"
                id="confirmationCode"
                placeholder="確認コードを入力"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="forgotpass-form">
              <Label for="newPassword" className="forgotpass-label">新しいパスワード</Label>
              <Input
                className="forgotpass-input"
                type="password"
                id="newPassword"
                placeholder="新しいパスワード（半角英数字を混ぜて8文字以上）"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormGroup>
            <Button className="forgotpass-button" color="primary" onClick={handleForgotPasswordSubmit}>パスワードをリセットする</Button>
          </>
        ) : (
          <>
            <FormGroup className="forgotpass-form">
              <Label for="email" className="forgotpass-label">Eメールアドレス</Label>
              <Input
                className="forgotpass-input"
                type="email"
                id="email"
                placeholder="Eメールアドレス"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <Button className="forgotpass-button" color="primary" onClick={handleForgotPassword}>コードを送信</Button>
          </>
        )}
        {error && <Alert color="danger">{error}</Alert>}
        <div className="signup-link" style={{ marginTop: "20px" }}>
        <Link to="/signin">ログインに戻る</Link>
      </div>
      </Form>
    </div>
  );
}

export default ForgotPassword;

