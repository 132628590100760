// tenshino.omamori/src/AmazonRedirect.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AmazonRedirect() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Amazon認証コードをバックエンドに送信
      fetch('/api/auth/amazon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
      .then(response => response.json())
      .then(data => {
        // ユーザー認証の状態を更新
        // ユーザープロファイルページへリダイレクト
        navigate('/user-profile');
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        navigate('/error'); // エラーページにリダイレクト
      });
    } else {
      setIsLoading(false);
    }
  }, [navigate]);

  if (isLoading) {
    return <div>リダイレクト中...</div>;
  }

  return <div>認証コードが見つかりませんでした。</div>;
}

export default AmazonRedirect;


