// CommunityPage.js
import React from 'react';

function CommunityPage() {
  return (
    <div>
      <h1>みんなのごはん堂</h1>
      <p>ここはコミュニティページです。</p>
    </div>
  );
}

export default CommunityPage;
