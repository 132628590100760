import React from 'react';
import { useParams } from 'react-router-dom';

function RecipeDetail() {
    const { id } = useParams();
    const recipes = JSON.parse(localStorage.getItem('recipes') || '[]');
    
    // 1. レシピのデータを確認
    console.log("All Recipes from LocalStorage:", recipes);
    const recipe = recipes.find(r => r.id === id);
    console.log("Matched Recipe:", recipe);

    if (!recipe) {
        return <div>レシピが見つかりませんでした。</div>;
    }

    // 2. カテゴリーのデータを確認
    console.log("Categories:", recipe.categories);

    return (
        <div>
            <h2>{recipe.name}</h2>
            
            <h3>使用食材</h3>
            <ul>
                {recipe.ingredients.map((ingredient, index) => (
                    <li key={index}>
                        {ingredient.ingredient} - {ingredient.amount} {ingredient.unit}
                    </li>
                ))}
            </ul>

            <h3>使用調味料</h3>
            <ul>
                {recipe.seasonings.map((seasoning, index) => (
                    <li key={index}>
                        {seasoning.ingredient} - {seasoning.amount} {seasoning.unit}
                    </li>
                ))}
            </ul>

            <h3>調理手順</h3>
            <p>{recipe.procedure}</p>

            <h3>画像</h3>
            {recipe.images && recipe.images.map((image, index) => (
                image && <img key={index} src={URL.createObjectURL(image)} alt={`Recipe ${index + 1}`} />
            ))}

            <h3>カテゴリー</h3>
            <ul>
                {Object.values(recipe.categories).map((category, index) => (
                    category && <li key={index}>{category}</li>
                ))}
            </ul>
        </div>
    );
}

export default RecipeDetail;








