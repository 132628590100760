// RecipeForm7.js
import React from 'react';
import Modal from 'react-modal';

// 必要に応じて以下のCSSを追加または変更してください。
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '40%',
        width: '70%', 
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',  // 白い背景
        borderRadius: '10px',  // 角を丸くする
        padding: '20px',  // パディングを追加する
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',  // 黒い背景に透明度を追加
    }
};

const RecipeForm7 = ({ openModal, isModalOpen, closeModal }) => {
    return (
        <div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Save Modal"
                style={modalStyles}  // 追加されたスタイルプロパティ
            >
                <div className="modal-header">
                    <h5 className="modal-title">メッセージ</h5>
                </div>
                <p>保存しました</p>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>OK</button>
                </div>
            </Modal>
        </div>
    );
};

export default RecipeForm7;





