// tenshino.omamori/src/Auth/SignIn.js
/* global amazon */

import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap'; // Bootstrap components
import { useNavigate } from 'react-router-dom';
import './SignIn.css'; // ここでCSSをインポート
import { Link } from 'react-router-dom'; // React RouterのLinkコンポーネントをインポート
import { UserContext } from '../UserContext';


function SignIn() {
  const { setUserId, setIsAuthenticated } = useContext(UserContext);
  
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

 // 成功または新しい操作の開始時にエラーメッセージをリセットする
 const resetError = () => {
  setError('');
};

// エラーメッセージの日本語変換
const translateErrorMessage = (message) => {
const translations = {
  'Username cannot be empty': 'メールアドレスを入力してください。',
  'Password does not conform to policy': 'パスワードはポリシーに従ってください。',
  'Invalid password format': '無効なパスワード形式です。',
  'User does not exist': 'ユーザーが存在しません。',
  'User already exists': 'ユーザーは既に存在しています。',
  'Incorrect username or password.': 'メールアドレスまたはパスワードが間違っています。',
  'An account with the given email already exists.': '指定されたメールアドレスのアカウントは既に存在しています。',
  'CodeMismatchException': '確認コードが一致しません。',
  'Invalid verification code provided, please try again.': '無効な確認コードです。もう一度お試しください。',
  'ExpiredCodeException': '確認コードの有効期限が切れています。',
  'NotAuthorizedException': 'この操作は許可されていません。',
  'Confirmation code cannot be empty': '確認コードを入力してください。',
  'PasswordResetRequiredException': 'パスワードのリセットが必要です。',
  'Username should be an email.': '正しいメールアドレスを入力してください。',
  'UserNotFoundException': 'ユーザーが見つかりません。',
  'TooManyFailedAttemptsException': '試行回数が多すぎます。後ほど再試行してください。',
  'TooManyRequestsException': 'リクエストが多すぎます。しばらくしてから再試行してください。',
  'NetworkError': 'ネットワークエラーが発生しました。接続を確認してください。',
  'User is not confirmed.': 'ユーザーはまだ確認されていません。',
  'Password did not conform with policy: Password not long enough': 'パスワードは半角英数字を混ぜて8文字以上で構成してください。',
  '1 validation error detected': '検証エラーが1件検出されました：パスワードが要件を満たしていません。',
  'Password cannot be empty': 'パスワードを入力してください。',
  'CUSTOM_AUTH is not enabled for the client': 'ログイン設定が正しくありません。',
  'Custom auth lambda trigger is not configured for the user pool.': 'カスタム認証Lambdaトリガーがユーザープールに設定されていません。',
  'Value at \'password\' failed to satisfy constraint': 'パスワードが条件を満たしていません。',
  'Member must satisfy regular expression pattern': '入力値は正規表現パターンを満たす必要があります。',
};
const errorKey = Object.keys(translations).find(key => message.includes(key));
return errorKey ? translations[errorKey] : message;
};





const handleSignIn = async () => {
  resetError(); // 新しいサインアップ試行の前にエラーをリセット
  try {
    const user = await Auth.signIn(email, password);
    const userId = user.attributes.sub; // Cognitoから提供される一意のユーザーIDを取得
    localStorage.setItem('userId', userId); // ローカルストレージにユーザーIDを保存
    setUserId(userId); // ContextにユーザーIDを保存
    setIsAuthenticated(true);

    // リダイレクト処理
    navigate('/home');
  } catch (e) {
    setError(translateErrorMessage(e.message || 'サインインに失敗しました。'));
  }
};


const handleAmazonLogin = () => {
  var options = {
    scope: 'profile email',  // スコープを 'profile email' に修正
    popup: true  // ポップアップを使用する
  };

  amazon.Login.authorize(options, (response) => {
    if (response.error) {
      console.error('Amazon login error:', response.error);
      return;
    }

    // Amazonから返された認証コードを取得
    const { code } = response;

    // Amazon認証コードをバックエンドに送信して処理
    handleAmazonCode(code);
  });
};

const handleAmazonCode = (code) => {
  fetch('/api/auth/amazon', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    // レスポンスに基づいてフロントエンドの処理を行う
    // 例: 認証状態の更新、ユーザーのリダイレクトなど
    setIsAuthenticated(true);
    navigate('/home');  // ホームページにリダイレクト
  })
  .catch(error => {
    console.error('Amazon login failed:', error);
  });
};


 
    

      return (
        <div className="signin-container">
            <Form>
                <FormGroup className="signin-form-group">
                    <Label for="email" className="signin-label">Eメールアドレス</Label>
                    <Input
                        className="signin-input"
                        type="email"
                        id="email"
                        placeholder="登録したメールアドレスを入力"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className="signin-form-group2">
                    <Label for="password" className="signin-label">パスワードを入力</Label>
                    <Input
                        className="signin-input"
                        type="password"
                        id="password"
                        placeholder="半角英数字8文字以上"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormGroup>
                <Button className="signin-button" color="primary" onClick={handleSignIn}>ログイン</Button>
            {/* Amazonログインボタン */}
            <button onClick={handleAmazonLogin}>Amazonでログイン</button>

            
            {/* パスワードを忘れた場合のリンクを追加 */}
        <div className="signin-link"style={{ marginTop: "20px" }}>
          <Link to="/forgotpassword">パスワードを忘れた場合</Link>
        </div>
            {/* 新規登録はこちらのリンク */}
      <div className="signin-link"style={{ marginTop: "10px" }}>
        <Link to="/signup">新規登録はこちら</Link>
      </div>
            </Form>
            {error && <Alert color="danger">{error}</Alert>}

        </div>
    );
}

export default SignIn;


