import React, { useState } from 'react';
import Modal from 'react-modal';  // この行を追加
import './RecipeForm5.css';
const modalStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '75%',
      width: '90%', 
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
  },
  overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
  }
};


// BlobをBase64に変換する関数
function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

const RecipeForm5 = ({ isModalOpen, closeModal, openModal }) => { 
  const [resizedImages, setResizedImages] = useState([null, null, null, null]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null); 

  const handleResizedImageChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await blobToBase64(file);
      const newResizedImages = [...resizedImages];
      newResizedImages[index] = base64;
      setResizedImages(newResizedImages);
    }
  };
  const handleImageClick = (index) => {
    setCurrentImageIndex(index); 
    openModal();
};

  const handleImageDelete = () => {  // 追加
    document.getElementById(`recipeImage-${currentImageIndex}`).value = '';  // この行を追加
    const newResizedImages = [...resizedImages];
    newResizedImages[currentImageIndex] = null;
    setResizedImages(newResizedImages);
    setCurrentImageIndex(null);  // オプション
    closeModal();
  };


  return (
    <div className="mb-3">
      <label className="header-text-shifted">レシピ画像（4枚まで）</label>
      <div className="image-upload-container">
        {resizedImages.map((image, index) => (
          <div key={index} className="image-upload-box">
            <input
              type="file"
              style={{ display: 'none' }}
              id={`recipeImage-${index}`}
              onChange={(e) => handleResizedImageChange(e, index)}
            />
            {image ? (
              <img
                src={image}  // Base64データを使用
                alt={`Recipe Preview ${index + 1}`}
                className="img-preview"
                onClick={() => handleImageClick(index)}
              />
            ) : (
              <div
                className="empty-image-upload"
                onClick={() => document.getElementById(`recipeImage-${index}`).click()}
              >
                画像をアップロード
              </div>
            )}
          </div>
        ))}
      </div>
      {isModalOpen && currentImageIndex !== null && (
    <Modal  // この行を変更
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={modalStyles}  // この行を追加
    >
        <div className="imagemodal-content" >
            
            <img 
                src={resizedImages[currentImageIndex]} 
                alt={`Large Recipe Preview ${currentImageIndex + 1}`} 
                style={{ 
                  objectFit: 'contain',  // 画像を親要素内に収め、アスペクト比を保持します。
                  maxHeight: '100%',  // 親要素の高さに基づいて画像の高さを制限します。
                  maxWidth: '100%',   // 親要素の幅に基づいて画像の幅を制限します。
                  position: 'absolute',  // 位置を絶対に設定します。
                  top: '50%',  // 上から50%の位置に設定します。
                  left: '50%',  // 左から50%の位置に設定します。
                  transform: 'translate(-50%, -50%)'  // 中央に配置するために調整します。
                 
                }}
            />
            </div>
            <div className="button-container">
            <button className="close-button" onClick={closeModal}>　閉じる　</button>
              <button className="delete-button" onClick={handleImageDelete}>画像を削除</button>
        </div>
        
    </Modal>  // この行を変更
)}
</div>
);
};

export default RecipeForm5;


