import React from 'react';

function PrivacyPolicy() {
    const policyText = `
    お客様から取得する情報
    当社は、お客様から以下の情報を取得します。
    　・氏名(ニックネームやペンネームも含む)
    　・年齢または生年月日
    　・性別
    　・職業、職歴、学歴
    　・メールアドレス
    　・電話番号
    　・住所
    　・写真や動画
    　・クレジットカード、銀行口座、電子マネー等のお客様の決済手段に関する情報
    　・外部サービスでお客様が利用するID、その他外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報
    　・Cookie(クッキー)を用いて生成された識別情報
    　・OSが生成するID、端末の種類、端末識別子等のお客様が利用するOSや端末に関する情報
    　・当社ウェブサイトの滞在時間、入力履歴、購買履歴等の当社ウェブサイトにおけるお客様の行動履歴
    　・当社アプリの起動時間、入力履歴、購買履歴等の当社アプリの利用履歴
    　・お客様の位置情報 
    
    お客様の情報を利用する目的 
    当社は、お客様から取得した情報を、以下の目的のために利用します。
    　・当社サービスに関する登録の受付、お客様の本人確認、認証のため
    　・お客様の当社サービスの利用履歴を管理するため
    　・利用料金の決済のため
    　・当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため
    　・広告の配信、表示及び効果測定のため
    　・お客様の趣味嗜好にあわせたターゲティング広告を表示するため
    　・当社のサービスに関するご案内をするため
    　・お客様からのお問い合わせに対応するため
    　・当社の規約や法令に違反する行為に対応するため
    　・当社サービスの変更、提供中止、終了、契約解除をご連絡するため
    　・当社規約の変更等を通知するため
    　・以上の他、当社サービスの提供、維持、保護及び改善のため
    
    安全管理のために講じた措置
    当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。
    
    第三者提供
    当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第１６条第３項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。
    但し、次の場合は除きます。
    　・個人データの取扱いを外部に委託する場合
    　・当社や当社サービスが買収された場合
    　・事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を別途公表します。）
    　・その他、法律によって合法的に第三者提供が許されている場合
    
    アクセス解析ツール
    　当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
    Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。
    　https://marketingplatform.google.com/about/analytics/terms/jp/
    
    広告の配信
    当社は、Google及びそのパートナー（第三者配信事業者）の提供する広告を設置しています。広告配信にはCookieを使用し、お客様が過去に当社ウェブサイトやその他のサイトにアクセスした情報に基づいて広告を配信します。Google やそのパートナーは、Cookieを使用することにより適切な広告を表示しています。
    お客様は、以下のGoogleアカウントの広告設定ページから、パーソナライズ広告を無効にできます。
    　https://adssettings.google.com/u/0/authenticated
    また aboutads.info のページにアクセスし、パーソナライズ広告掲載に使用される第三者配信事業者のCookieを無効にすることもできます。
    その他、GoogleによるCookieの取り扱い詳細については、以下のGoogleのポリシーと規約のページをご覧ください。
    　https://policies.google.com/technologies/ads
    
    プライバシーポリシーの変更
    当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。
    
    お問い合わせ
    お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のメールアドレスにご連絡ください。
    　e-mail
    　　letsgohan_info@yahoo.co.jp
    この場合、必ず、運転免許証のご提示等当社が指定する方法により、ご本人からのご請求であることの確認をさせていただきます。なお、情報の開示請求については、開示の有無に関わらず、ご申請時に一件あたり1,000円の事務手数料を申し受けます。
    
    事業者の氏名
    　
    
    事業者の住所
    　
    　2023年11月15日 制定`;
    　
    　


    return (
        <div style={{ textAlign: 'left' }}>
          <h1>プライバシーポリシー</h1>
          {policyText.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              <span>{line}</span><br />
            </React.Fragment>
            ))}
        </div>
    );
}

export default PrivacyPolicy;
