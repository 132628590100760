// src/Auth/SignUp.js

import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import './SignUp.css'; // ここで新しいCSSファイルをインポートします
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // 成功または新しい操作の開始時にエラーメッセージをリセットする
  const resetError = () => {
    setError('');
  };

// エラーメッセージの日本語変換
const translateErrorMessage = (message) => {
  const translations = {
    'Username cannot be empty': 'メールアドレスを入力してください。',
    'Password does not conform to policy': 'パスワードはポリシーに従ってください。',
    'Invalid password format': '無効なパスワード形式です。',
    'User does not exist': 'ユーザーが存在しません。',
    'User already exists': 'ユーザーは既に存在しています。',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが間違っています。',
    'An account with the given email already exists.': '指定されたメールアドレスのアカウントは既に存在しています。',
    'CodeMismatchException': '確認コードが一致しません。',
    'Invalid verification code provided, please try again.': '無効な確認コードです。もう一度お試しください。',
    'ExpiredCodeException': '確認コードの有効期限が切れています。',
    'NotAuthorizedException': 'この操作は許可されていません。',
    'Confirmation code cannot be empty': '確認コードを入力してください。',
    'PasswordResetRequiredException': 'パスワードのリセットが必要です。',
    'Username should be an email.': '正しいメールアドレスを入力してください。',
    'UserNotFoundException': 'ユーザーが見つかりません。',
    'TooManyFailedAttemptsException': '試行回数が多すぎます。後ほど再試行してください。',
    'TooManyRequestsException': 'リクエストが多すぎます。しばらくしてから再試行してください。',
    'NetworkError': 'ネットワークエラーが発生しました。接続を確認してください。',
    'User is not confirmed.': 'ユーザーはまだ確認されていません。',
    'Password did not conform with policy: Password not long enough': 'パスワードは半角英数字を混ぜて8文字以上で構成してください。',
    '1 validation error detected': '検証エラーが1件検出されました：パスワードが要件を満たしていません。',
    'Password cannot be empty': 'パスワードを入力してください。',
    'CUSTOM_AUTH is not enabled for the client': 'ログイン設定が正しくありません。',
    'Custom auth lambda trigger is not configured for the user pool.': 'カスタム認証Lambdaトリガーがユーザープールに設定されていません。',
    'Value at \'password\' failed to satisfy constraint': 'パスワードが条件を満たしていません。',
    'Member must satisfy regular expression pattern': '入力値は正規表現パターンを満たす必要があります。',
  };
  const errorKey = Object.keys(translations).find(key => message.includes(key));
  return errorKey ? translations[errorKey] : message;
};


const handleSignUp = async () => {
  resetError(); // 新しいサインアップ試行の前にエラーをリセット
  try {
    await Auth.signUp({
      username: email,
      password,
    });
    setIsConfirmation(true); // 確認コードのページに移動する前に状態を変更
  } catch (e) {
    setError(translateErrorMessage(e.message || 'サインアップに失敗しました。'));
  }
};

const handleConfirmSignUp = async () => {
  resetError(); // 新しい確認試行の前にエラーをリセット
  try {
    // ユーザーの確認を行う
    await Auth.confirmSignUp(email, confirmationCode);

    // 自動的にサインイン
    const user = await Auth.signIn(email, password);
    const userId = user.attributes.sub; // Cognitoから提供される一意のユーザーIDを取得
    localStorage.setItem('userId', userId); // ローカルストレージにユーザーIDを保存
    // 必要に応じて他の認証関連のステートを設定

    // ホームページへリダイレクト
    navigate('/home');
  } catch (e) {
    setError(translateErrorMessage(e.message || '確認コードの送信に失敗しました'));
  }
};


  return (
    <div className="signup-container">
      <Form>
        {isConfirmation ? (
          <div>
            <FormGroup className="signup-form-group">
              <Label for="confirmationCode" className="signup-label">確認コード</Label>
              <Input
                className="signup-input"
                type="text"
                id="confirmationCode"
                placeholder="メールで届いた確認コードを入力してください"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
              />
            </FormGroup>
            <Button className="signup-button" color="primary" onClick={handleConfirmSignUp}>送信</Button>
          </div>
        ) : (
          <div>
            <FormGroup className="signup-form-group">
              <Label for="email" className="signup-label">メールアドレス</Label>
              <Input
                className="signup-input"
                type="email"
                id="email"
                placeholder="メールアドレスを入力"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="signup-form-group2">
              <Label for="password" className="signup-label">パスワード</Label>
              <Input
                className="signup-input"
                type="password"
                id="password"
                placeholder="パスワードを入力（半角英数字を混ぜて8文字以上）"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Label for="password" className="signup-label2">登録アドレスに届いた確認コードを次のページで入力したら完了です。</Label>
            </FormGroup>
            
            <Button className="signup-button" color="primary" onClick={handleSignUp}>送信</Button>
          
          </div>
          
        )}
        {error && <Alert color="danger">{error}</Alert>}
        {/* 新規登録済みの方へのリンクを追加 */}
      <div className="signup-link" style={{ marginTop: "20px" }}>
        <Link to="/signin">登録済みの方はこちら</Link>
      </div>
      </Form>
    </div>
  );
}

export default SignUp;

