export const uploadFileToS3 = async (file, bucketName, key) => {
  const formData = new FormData();
  formData.append('file', file); // ファイルの追加
  formData.append('bucketName', bucketName); // バケット名の追加
  formData.append('key', key); // キーの追加

  try {
    const response = await fetch('/api/upload-to-s3', {
      method: 'POST',
      body: formData // ここでファイルデータを送信
    });
    if (!response.ok) {
      throw new Error('アップロードに失敗しました');
    }
    const data = await response.json();
    console.log("Success", data);
    return data;
  } catch (err) {
    console.error("Error in upload:", err);
    throw err;
  }
};

export const deleteFileFromS3 = async (bucketName, key) => {
  try {
    const response = await fetch(`/api/delete-from-s3`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ bucketName, key })
    });
    if (!response.ok) {
      throw new Error('削除に失敗しました');
    }
    const data = await response.json();
    console.log("File deleted successfully", data);
    return data;
  } catch (err) {
    console.error("Error in deleting file", err);
    throw err;
  }
};
